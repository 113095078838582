<template>
  <div>
    <div class="listwork">
      <div class="listwork__top">
        <h4>Danh sách ngành nghề kinh doanh</h4>
      </div>
      <div class="listwork__table__01">
        <!-- ===============================================bắt dầu bảng nhóm ngành nghề kinh doanh hợp lệ-------------------------------- -->
        <vue-good-table
          :select-options="{ enabled: false , selectOnCheckboxOnly: true,}"
          :columns="columns"
          :rows="dataInvalid || []"
          style-class="vgt-table"
        >
        </vue-good-table>
        <!-- ================================================kết thúc bảng nhóm ngành nghề kinh doanh hợp lệ-------------------------------- -->
      </div>
      <div class="listwork__text__center">
        <p>Tổng cộng: {{ dataInvalid.length }} ngành nghề kinh doanh</p>
      </div>
      <div class="listwork__head__02">
        <div class="listwork__head__02__left">
          <h4>Danh sách ngành nghề kinh doanh không hợp lệ</h4>
        </div>
        <!-- ================================================nút chỉnh sửa  -------------------------------- -->
        <div class="listwork__head__02__right">
          <b-button
            v-b-tooltip.hover.top="'Chỉnh sửa'"
            variant="primary"
            class="btn-icon"
            @click="onOffEdit"
          >
            <feather-icon icon="EditIcon" />
          </b-button>
          <!-- ================================================nút kiểm tra  -------------------------------- -->
          <div class="listwork__head__02__right__icon">
            <b-button
              v-b-tooltip.hover.top="'Kiểm tra'"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              class="btn-icon"
              @click="CheckdataNoInvalid"
            >
              <feather-icon icon="CheckCircleIcon" />
            </b-button>
          </div>

          <div />
        </div>

      </div>
      <div class="listwork__table__02">
        <!-- ===============================================bắt dầu bảng nhóm ngành nghề kinh doanh không hợp lệ-------------------------------- -->
        <vue-good-table
          :fixed-header="true"
          :columns="columns02"
          :rows="dataNoInvalid"
          :select-options="{ enabled: false}"
          style-class="vgt-table"
        >

          <template
            slot="table-row"
            slot-scope="props"
          >
            <!--======================= Thêm icon lỗi =================================== -->
            <span
              v-if="props.column.field == 'icon02'"
              :id="'tooltip' + props.row.originalIndex"
              v-b-tooltip.hover.right.html="props.row.messageError"
              variant="outline-danger"
            >
              <feather-icon
                icon="AlertTriangleIcon"
                color="red"
              />
            </span>
            <!--======================= Color cho vị trí bị lỗi =================================== -->

            <!--======================= Tên ngành nghề kinh doanh lỗi =================================== -->
            <span v-if="props.column.field == 'name' && editvalueErr === false ">
              <span
                :class="
                  props.row.messErr[0].location === 'Name'? 'text-danger' :'' "
              >{{ props.row.name }}</span>
            </span>

            <span v-if="props.column.field == 'name' && editvalueErr === true ">
              <input
                v-if="props.row.messErr[0] && props.row.messErr[0].location === 'Name'"
                v-model="props.row.name"
                type="text"
                class="cssInput"
                @input="changeCellvalue(props.row.name, 'name', props.row)"
              >
              <span
                v-if="props.row.messErr[0] && props.row.messErr[0].location !== 'Name'"
              >{{ props.row.name }}</span>
            </span>

            <!--======================= Cấp ngành nghề kinh doanh lỗi =================================== -->
            <span v-if="props.column.field == 'level1' && editvalueErr === false">
              <span
                :class="
                  props.row.messErr[0].location === 'Level1'? 'text-danger' :'' "
              >{{ props.row.level1 }}</span>
            </span>

            <span v-if="props.column.field == 'level1' && editvalueErr === true ">
              <input
                v-if="props.row.messErr[0] && props.row.messErr[0].location === 'Level1'"
                v-model="props.row.level1"
                type="text"
                class="cssInput"
                @input="changeCellvalue(props.row.level1, 'level1', props.row)"
              >
              <span
                v-if="props.row.messErr[0] && props.row.messErr[0].location !== 'Level1'"
              >{{ props.row.level1 }}</span>
            </span>

            <!--======================= Cấp ngành nghề kinh doanh lỗi =================================== -->
            <span v-if="props.column.field == 'level2' && editvalueErr === false">
              <span
                :class="
                  props.row.messErr[0].location === 'Level2'? 'text-danger' :'' "
              >{{ props.row.level2 }}</span>
            </span>

            <span v-if="props.column.field == 'level2' && editvalueErr === true ">
              <input
                v-if="props.row.messErr[0] && props.row.messErr[0].location === 'Level2'"
                v-model="props.row.level2"
                type="text"
                class="cssInput"
                @input="changeCellvalue(props.row.level2, 'level2', props.row)"
              >
              <span
                v-if="props.row.messErr[0] && props.row.messErr[0].location !== 'Level2'"
              >{{ props.row.level2 }}</span>
            </span>

            <!--======================= Cấp ngành nghề kinh doanh lỗi =================================== -->
            <span v-if="props.column.field == 'level3' && editvalueErr === false">
              <span
                :class="
                  props.row.messErr[0].location === 'Level3'? 'text-danger' :'' "
              >{{ props.row.level3 }}</span>
            </span>

            <span v-if="props.column.field == 'level3' && editvalueErr === true ">
              <input
                v-if="props.row.messErr[0] && props.row.messErr[0].location === 'Level3'"
                v-model="props.row.level3"
                type="text"
                class="cssInput"
                @input="changeCellvalue(props.row.level3, 'level3', props.row)"
              >
              <span
                v-if="props.row.messErr[0] && props.row.messErr[0].location !== 'Level3'"
              >{{ props.row.level3 }}</span>
            </span>

            <!--======================= Cấp ngành nghề kinh doanh lỗi =================================== -->
            <span v-if="props.column.field == 'level4' && editvalueErr === false">
              <span
                :class="
                  props.row.messErr[0].location === 'Level4'? 'text-danger' :'' "
              >{{ props.row.level4 }}</span>
            </span>

            <span v-if="props.column.field == 'level4' && editvalueErr === true ">
              <input
                v-if="props.row.messErr[0] && props.row.messErr[0].location === 'Level4'"
                v-model="props.row.level4"
                type="text"
                class="cssInput"
                @input="changeCellvalue(props.row.level4, 'level4', props.row)"
              >
              <span
                v-if="props.row.messErr[0] && props.row.messErr[0].location !== 'Level4'"
              >{{ props.row.level4 }}</span>
            </span>

            <!--======================= Ngành nghề kinh doanh cha lỗi =================================== -->
            <span v-if="props.column.field == 'parentId' && editvalueErr === false">
              <span
                :class="
                  props.row.messErr[0].location === 'ParentId'? 'text-danger' :'' "
              >{{ props.row.parentId }}</span>
            </span>

            <span v-if="props.column.field == 'parentId' && editvalueErr === true ">
              <business-sector-select
                v-if="props.row.messErr[0] && props.row.messErr[0].location === 'ParentId'"
                v-model="props.row.parentIdName"
                @input="changeCellvalueOr(props.row.parentId, 'parentId', props.row)"
                @checkNameText="checkNameText"
              />
              <span
                v-if="props.row.messErr[0] && props.row.messErr[0].location !== 'ParentId'"
              >{{ props.row.parentId }}</span>
            </span>

            <!--======================= Cấp ngành nghề kinh doanh lỗi =================================== -->
            <span v-if="props.column.field == 'level5' && editvalueErr === false">
              <span
                :class="
                  props.row.messErr[0].location === 'Level5'? 'text-danger' :'' "
              >{{ props.row.level5 }}</span>
            </span>

            <span v-if="props.column.field == 'level5' && editvalueErr === true ">
              <input
                v-if="props.row.messErr[0] && props.row.messErr[0].location === 'Level5'"
                v-model="props.row.level5"
                type="text"
                class="cssInput"
                @input="changeCellvalue(props.row.level5, 'level5', props.row)"
              >
              <span
                v-if="props.row.messErr[0] && props.row.messErr[0].location !== 'Level5'"
              >{{ props.row.level5 }}</span>
            </span>

            <span v-if="props.column.field == 'description'">
              <span>{{ props.row.description }}</span>
            </span>

          </template>
        </vue-good-table>
        <!-- ================================================kết thúc bảng ngành nghề kinh doanh không hợp lệ-------------------------------- -->
      </div>
      <div class="listwork__text__center">
        <p>Tổng cộng: {{ dataNoInvalid.length }} ngành nghề kinh doanh</p>
      </div>

      <div class="listwork__button">
        <!-- ================================================nút thêm nhóm ngành nghề kinh doanh-------------------------------- -->
        <div class="listwork__button__item">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            @click="addUser"
          >
            Thêm ngành nghề kinh doanh
          </b-button>
        </div>
        <!-- ================================================nút hủy bỏ  -------------------------------- -->
        <div class="listwork__button__item">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            @click="closeExcel"
          >
            Hủy bỏ
          </b-button>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { formatFullName, formatDateToDDMM } from '@core/utils/filter'
import {
  BButton, BBreadcrumb, BBreadcrumbItem, VBTooltip,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import Ripple from 'vue-ripple-directive'
import VueGoodTable from '@/components/table/VueGoodTable.vue'
import BusinessSectorSelect from './BusinessSectorSelect.vue'

export default {
  components: {
    VueGoodTable,
    BButton,
    BBreadcrumb,
    BBreadcrumbItem,
    VBTooltip,
    BusinessSectorSelect,

  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  filters: {
    formatDateToDDMM,
  },
  data() {
    return {
      formatFullName,
      dataInvalid: [],
      dataNoInvalid: [],
      editvalueErr: false, // bật tắt chỉnh sửa
      columns: [
        {
          label: 'Level 1',
          field: 'level1',
          sortable: false,
        },
        {
          label: 'Level 2',
          field: 'level2',
          sortable: false,
        },
        {
          label: 'Level 3',
          field: 'level3',
          sortable: false,
        },
        {
          label: 'Level 4',
          field: 'level4',
          sortable: false,
        },
        {
          label: 'Level 5',
          field: 'level5',
          sortable: false,
        },
        {
          label: 'Tên ngành nghề kinh doanh',
          field: 'name',
          sortable: false,
        },
        {
          label: 'Ngành nghề kinh doanh cha',
          field: 'parentId',
          sortable: false,
        },
        {
          label: 'Mô tả',
          field: 'description',
          sortable: false,
        },
      ],
      columns02: [
        {
          label: '',
          field: 'icon02',
          sortable: false,
        },
        {
          label: 'Level 1',
          field: 'level1',
          sortable: false,
        },
        {
          label: 'Level 2',
          field: 'level2',
          sortable: false,
        },
        {
          label: 'Level 3',
          field: 'level3',
          sortable: false,
        },
        {
          label: 'Level 4',
          field: 'level4',
          sortable: false,
        },
        {
          label: 'Level 5',
          field: 'level5',
          sortable: false,
        },
        {
          label: 'Tên ngành nghề kinh doanh',
          field: 'name',
          sortable: false,
        },
        {
          label: 'Ngành nghề kinh doanh cha',
          field: 'parentId',
          sortable: false,
        },
        {
          label: 'Mô tả',
          field: 'description',
          sortable: false,
        },
      ],
      businessName: null,
    }
  },
  computed: {
    ...mapGetters('businessSector', ['dataValidUser', 'dataNoValidUser', 'dataTooltip']),
  },
  created() {
    this.dataInvalid = this.dataValidUser
    this.dataNoInvalid = this.dataNoValidUser
    this.checkErrorUser(this.dataTooltip)
    this.$hideAllPageLoading()
  },
  methods: {
    ...mapActions('businessSector', ['getApiExcelBusinessSector']),

    checkNameText(value) {
      this.businessName = value.name
    },

    // BẬT tắt chỉnh sửa
    onOffEdit() {
      this.editvalueErr = !this.editvalueErr
    },
    // thay đổi dữ liệu trên bảng
    changeCellvalue(event, field, rowData) {
      this.dataNoInvalid[rowData.originalIndex][field] = event
    },
    async changeCellvalueOr(event, field, rowData) {
      this.dataNoInvalid[rowData.originalIndex][field] = this.businessName
    },
    // nút check
    async CheckdataNoInvalid() {
      const model = {
        listExcel: this.dataInvalid.concat(this.dataNoInvalid),
        isValidate: true,
      }
      this.$showAllPageLoading()
      const valuedata = await this.getApiExcelBusinessSector(model)
      this.dataInvalid = valuedata.data.data.filter(x => x.isSuccess === true)
      const dataTemp = valuedata.data.data.filter(x => x.isSuccess === false)
      this.$hideAllPageLoading()
      if (dataTemp.length > 0) {
        this.dataNoInvalid = dataTemp
        this.checkErrorUser(this.dataNoInvalid)
      } else {
        this.dataNoInvalid = []
      }
    },
    addUser() {
      const model = {
        listExcel: this.dataInvalid,
        isValidate: false,
      }
      this.$showAllPageLoading()
      this.getApiExcelBusinessSector(model).then(res => {
        if (res.status === 200) {
          this.$hideAllPageLoading()
          this.$root.$bvToast.toast('Thêm thành công', {
            title: 'Thông báo',
            variant: 'success',
            toaster: this.$toastPosition,
            solid: true,
          })
          this.dataInvalid = []
          if (this.dataNoInvalid.length === 0) {
            this.$router.push({ name: 'list-businessSector' })
          }
        } else {
          this.$hideAllPageLoading()
          this.$root.$bvToast.toast('Thêm không thành công', {
            title: 'Thông báo',
            variant: 'danger',
            toaster: this.$toastPosition,
            solid: true,
          })
        }
      })
    },

    checkErrorUser(data) {
      // kiểm tra lỗi
      if (data && data.length > 0) {
        for (let i = 0; i < data.length; i++) {
          const element = data[i]
          if (element.isSuccess === false) {
            /* eslint no-loop-func: "error" */
            let messageError = ''
            if (element.messErr) {
              element.messErr.forEach(err => {
                messageError += `${this.$t(`${err.message}`)} <br> `
              })
            }
            data[i].messageError = messageError
          } else { element.isSelected = true }
        }
      }
    },

    closeExcel() {
      this.dataInvalid = []
      this.dataNoInvalid = []
      this.$router.push({ name: 'list-businessSector' })
    },
  },
}
</script>

<style lang='scss'>
.listwork{
background: white;
box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
border-radius: 6px;

}

.listwork__top{
padding:20px;}
.listwork__top h4{
font-family: Montserrat;
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 22px;

color: #181F28
}
.listwork__text__center p{
font-family: Montserrat;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 21px;
text-align: right;
margin-top:20px;
padding-right:20px;

}
.listwork__head__02{
display: flex;
align-items: center;
justify-content: space-between;
padding:20px;
}
.listwork__head__02__left h4{
font-family: Montserrat;
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 22px;
color: #000000;
}
.listwork__head__02__right{
display: flex;
align-items: center;}
.listwork__head__02__right__icon{
margin-left: 20px;}

.listwork__button{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top:40px;
  padding-right: 20px;
  padding-bottom: 20px;
}
.listwork__button__item{
  margin-left:20px
}

/* Breadcrumb  */
.header{
display:flex;
margin-bottom: 32px;

}
.header-title{
height:26px;
font-weight: 500;
font-size: 21px;
line-height:25,6px;
padding-right: 20px;
position: relative;
color:#2E3A4A;
}
.header-title::after{
  content:"";
  display: block;
  position: absolute;

    right: 0;
    top: 0;

    width:1px;
    height:26px;
    background-color: #CBCDD2;
}
.header-breadcrumb{
font-size:14px;
height:21px;
line-height:21px;
display: flex;
align-content: center;
padding: 0;
}
a {
    color: #227FF4;
    text-decoration: none;
}
.breadcrumb-item.active {
    color: #2E3A4A;
}
[dir] .breadcrumb{
padding-left: 20px;
}
/* end breadcrumb */

.text-danger{
  color:red
}
.cssInput{
  outline: none;
  border: 0px;
  border-bottom: 0.2px solid gray;
}

</style>
